@use '@vg-constellation/angular-16/styles/variables/tokens';
@use '@vg-constellation/angular-16/styles/typography';

ol.custom-c11n-list--large {
    .list-item {
        &__heading {
            @include typography.c11n-font-rules('bold', tokens.$c11n-size-typography-md);

            margin-bottom: tokens.$c11n-size-space-0point5x;
        }

        &__content {
            @include typography.c11n-font-rules('regular', tokens.$c11n-size-typography-sm);

            margin-bottom: tokens.$c11n-size-space-6x;
        }
    }

    > li::before {
        background: tokens.$c11n-color-ink-100;
    }
}
