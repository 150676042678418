@use '@vg-constellation/angular-16/styles/variables/tokens';

.zci-custom-radio {
    &--bottom-bordered {
        padding: tokens.$c11n-size-space-2x 0;
        border-bottom: 1px solid tokens.$c11n-color-ink-300;

        .c11n-radio.c11n-radio {
            input[c11nradioinput] ~ label.c11n-radio__label {
                margin: inherit;
            }
        }
    }
}
