@use '@vg-constellation/angular-16/styles/breakpoints';
@use './breakpoint-names';
@use '@vg-constellation/angular-16/styles/typography';

$namespace: 'zci';
$heavy-font-type: 'heavy';
$bold-font-type: 'heavy';
$font-size-h2-desktop: 'lg';
$font-size-h1-desktop: 'xl';

.#{$namespace}-h1 {
    @include typography.c11n-font-rules($heavy-font-type, $font-size-h1-desktop);
}

.#{$namespace}-h2 {
    @include typography.c11n-font-rules($bold-font-type, $font-size-h2-desktop);
}
