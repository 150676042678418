@use '@vg-constellation/angular-16/styles/variables/tokens';
@use '@vg-constellation/angular-16/styles/breakpoints';
@use './breakpoint-names';

$namespace: 'zci';

.#{$namespace}-container-#{breakpoint-names.$md} {
    max-width: tokens.$c11n-grid-max-width-md;
    margin: auto;
}

.#{$namespace}-container-#{breakpoint-names.$sm} {
    max-width: tokens.$c11n-grid-max-width-sm;
    margin: auto;
}
.#{$namespace}-container-#{breakpoint-names.$lg} {
    max-width: tokens.$c11n-grid-max-width-lg;
    margin: auto;
}
