@use '@vg-constellation/angular-16/styles/variables/tokens';
@use '@vg-constellation/angular-16/styles/functions';

$custom-c11n-svg: 'c11n-icon[custom-c11n].custom-c11n-svg';

#{$custom-c11n-svg} {
    &--fill-turquoise {
        svg[class*='c11n-icon'] > use {
            fill: tokens.$c11n-color-turquoise-600;
        }
    }

    &--fill-error {
        svg[class*='c11n-icon'] > use {
            fill: tokens.$c11n-color-alert-400;
        }
    }

    &--size-large {
        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &--size-extra-large {
        svg {
            width: 5rem;
            height: 5rem;
        }
    }

    &--size-small {
        svg {
            width: functions.rem(14px);
            height: functions.rem(14px);
        }
    }

    &--fill-black {
        svg[class*='c11n-icon'] > use {
            fill: tokens.$c11n-color-ink-800;
        }
    }
}
